.login-page-layout {
  height: 100vh;
  padding: 10%;
  background-color: #46AF5A;
  align-items: center;

  @media screen and(max-width:992px) {
    padding: 0px;


  }

  .login-page-content {
    padding-top: 20px;
    text-align: center;
    width: 50%;
    background-color: #46AF5A;

    @media screen and(max-width:992px) {
      width: 100% !important;
    }

    img {
      max-width: 400px;

      @media screen and(max-width:992px) {
        padding: 40px 20px;
        width: 100%;
      }
    }

    .login-page-form {
      padding: 64px;
      margin: 64px;
      background-color: white;

      @media screen and(max-width:500px) {
        padding: 20px;
        margin: 20px;
      }
    }
  }
}
