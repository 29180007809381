@import '../../antd.overrides.less';

.fab-button {
  position: fixed !important;
  bottom: 5%;
  right: 2%;
  width: 60px !important;
  height: 60px !important;
  font-size: x-large !important;
  box-shadow: 0px 14px 22px rgba(0, 0, 0, 0.25);
  z-index: 99999;
}

.filter-bar {
  padding: 24px 6px;
  background: #efefef;
  margin-bottom: 12px;
}

.logistics {
  .first-title {
    h1 {
      color: rgb(0, 51, 66) !important;
      font-size: 36px;
    }
  }

  .card-item-title {
    font-size: 64px;
    padding: 12px;
    background: @white-color;
    box-shadow: 0px 6px 10px @black-color;
    border-radius: 4px;
    text-align: center;
    color: rgb(0, 51, 66) !important;
    font-weight: bold;
    width: 200px;
  }

  /* ---------------------------------- tabs ---------------------------------- */
  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgb(0, 51, 66) !important;
  }

  .ant-tabs-ink-bar {
    background: rgb(0, 51, 66) !important;
  }

  .action-btn {
    color: @white-color;
    background-color: @layout-trigger-background;
  }

  .export-btn {
    width: 15% !important;
  }
  
}
