@import url('../../antd.overrides.less');

.first-title {
  h1 {

    font-size: 36px;
  }
}

.next-incubation-bulk-action-card {
  cursor: pointer;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  border: none;


  h3 {
    color: black;

  }

  .next-incubation-bulk-action-card-btn {
    background-color: white;
    color: @black;
    height: 80px;
    width: 80px;
    border: none;
    font-size: 30px;
    border-radius: 8px;
  }

  .next-incubation-bulk-action-label {
    background-color: white;
    color: @black;
    height: 36px;
    font-weight: 600;
    font-size: 14px;
    border: none;
    text-transform: uppercase;
  }
}

.next-incubation-fab-button {
  position: fixed;
  bottom: 5%;
  right: 2%;
  width: 60px;
  height: 60px;
  font-size: 32px;
  box-shadow: 0px 14px 22px rgba(0, 0, 0, 0.25);
  z-index: 999999;
}

.next-incubation-title {
  padding: 24px 0;
}

.batch-list,
.batch-list {
  .batch-list-filter {
    padding: 24px 12px;
    background-color: @secondary-color;
    margin-bottom: 12px;

    .next-incubation-search {
      text-align: right;
    }
  }

  .batch-card-item {
    margin-bottom: 12px;
    border-radius: 6px;
    border: none;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2) !important;

    .batch-card-btn-more {
      text-align: end;
      color: @primary-color;

      .batch-card-btn-more-content {
        background-color: @secondary-color;
        border: none;
      }
    }

    .batch-card-identifier {
      background: #ffffff;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      width: 80px;
      height: 80px;
      text-align: center;

      h3 {
        color: @primary-color;
        font-size: 32px;
      }

      .ant-card-body {
        padding: 10px;
        padding-top: 18px;
      }
    }
  }
}

.content-card-details {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  height: 40px;
  padding: 9px;
  margin-bottom: 10px;
}

.tag-time {
  background: rgba(70, 174, 91, 0.1);
  border-radius: 100px;
  color: @primary-color;
  padding: 6px 20px;
  border: none;
}

.tag-time-off {
  background: rgba(214, 56, 79, 0.1);
  border-radius: 100px;
  color: #d2364d;
  padding: 6px 20px;
  border: none;
}

.ant-tabs {
  color: black;
}

.process-killed-batch {
  margin: 12px !important;

  .col-right {
    text-align: right;

    .Dried,
    .killed,
    .process-killing-batch-status {
      padding: 6px 12px;
      border-radius: 6px;
      color: black;
    }

    .Dried {
      background-color: rgb(102, 139, 150) !important;
    }

    .killed {
      background-color: rgb(204, 216, 220) !important;
    }

    .P1 {
      background-color: rgb(8, 62, 80) !important;
    }

    .P2 {
      background-color: rgb(8, 62, 80) !important;
    }
  }

}



.next-process-page {
  .first-title {
    h1 {
      color: @red-color  !important;
      font-size: 36px;
    }
  }

  .card-item-title {
    font-size: 64px;
    padding: 12px;
    background: @white-color;
    box-shadow: 0px 6px 10px @black-color;
    border-radius: 4px;
    text-align: center;
    color: @red-color  !important;
    font-weight: bold;
    width: 200px;
  }

  /* ---------------------------------- tabs ---------------------------------- */
  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @red-color  !important;
  }

  .card-item-header .card-item-title {
    color: @green-color  !important;
  }

  .ant-tabs-ink-bar {
    background: @red-color  !important;
  }

  /* ---------------------------------- days ---------------------------------- */
  .card-item-container .card-item .card-item-tag .tag-time-off {
    background: @red-color  !important;
    color: @white-color  !important;
  }

  .output-btn {
    width: 10vw;
    height: 4vh;
  }

  .tiny-row {
    width: 30%;
  }
}

.next-process-list {
  a {
    color: black !important;
  }

  a:hover {
    cursor: pointer;
  }
}

