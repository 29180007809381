@import url('../antd.overrides.less');

#top-bar {
  background-color: #46AF5A !important;

  #logo {
    width: 120px;
    height: auto;
  }

  .ant-btn {
    width: 10vw;
  }

  .logout-btn {
    margin-left: 20px;
    width: 100px;
    background: transparent;
    border: 1px solid @white-color;
    color: @white-color;
  }
}

.menu-side-item {
  margin-right: 10px !important;
  margin-left: 10px !important;
  border-radius: 4px;

  a {

    &:active,
    &:focus {
      color: @white-color  !important;
    }
  }
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: transparent !important;
}

.menu-side-item-active-nextIncubator,
.menu-side-item-active-nextLarvae,
.menu-side-item-active-nextProcess,
.menu-side-item-active-nextBreeding,
.menu-side-item-active-nextFly,
.menu-side-item-active-nextFeed,
.menu-side-item-active-logistics,
.menu-side-item-active-dashboardBatchId {
  a {
    color: @white-color;

    &:active,
    &:focus {
      color: @white-color  !important;
    }
  }
}

.menu-side-item-active-nextIncubator {
  background-color: @blue-color  !important;
}

.menu-side-item-active-nextLarvae {
  background-color: @light-green-color  !important;
}

.menu-side-item-active-nextProcess {
  background-color: @red-color  !important;
}

.menu-side-item-active-nextFly {
  background-color: @yellow-color  !important;
}

.menu-side-item-active-nextFeed {
  background-color: @green-color  !important;
}

.menu-side-item-active-nextBreeding {
  background-color: @purple-color  !important;
}

.menu-side-item-active-dashboardBatchId {
  background-color: gray !important; // @TODOS: Change me
}

.menu-side-item-active-logistics {
  background-color: rgb(0, 51, 66) !important; // @TODOS: Change me
}

.side-menu {
  min-height: 100vh;
}
