@import '../../antd.overrides.less';

.card-list-filter-bar {

  margin-bottom: 12px;


  .switch-input {
    display: flex;
    align-items: center;

    button {
      margin-right: 10px;
    }
  }

  /* --------------------------- search input/button -------------------------- */
  .ant-input-affix-wrapper {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .ant-input-search-button {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-left: none !important;
    border-color: #ccc !important;
  }

  .search-input,
  .date-picker-input {
    width: 200px;
    border-color: #ccc !important;
    border-radius: 4px !important;
  }
}

.card-item-container {
  .card-item {
    border: 1px solid #eee;
    border-radius: 6px;


    // min-height: 340px;

    .ant-card-body {
      padding: 0px 24px !important;
    }

    .card-item-header {
      margin-bottom: 12px;

      .card-item-title {
        font-size: 16px;
        padding: 8px;
        background: #ffffff;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        text-align: center;
        color: @primary-color;
        font-weight: bold;
      }

      .card-item-actions {
        .card-item-actions-dropdown {
          font-size: 26px;
          color: #ccc;
        }
      }
    }

    .card-item-tag {
      height: 40px;
      text-align: right;
      padding-bottom: 6px;

      .tag-time-off {
        margin-right: 0 !important;
        background: rgba(214, 56, 79, 0.1);
        color: #d2364d;
        border-radius: 10px;
        padding: 6px 12px;
        border: none;
      }

      .tag-time {
        background: rgba(70, 174, 91, 0.1);
        color: @primary-color;
        border-radius: 10px;
        padding: 6px 12px;
        border: none;

      }

      .sieved-tag {
        background: #D6C8EE;
        border-radius: 10px;
        padding: 6px 12px;
        border: none;
      }
    }

    .card-item-footer-btn-details {
      border: 2px solid #46ae5b;
      color: #46ae5b;
      margin: 10px 0 20px 0 !important;
      text-transform: uppercase;
    }


    .marginZero {
      margin: 0 !important;
    }


    .card-item-content {



      .card-item-footer-btn-more {
        color: #46ae5b;
        margin: auto;
        padding: 0;
      }




      .card-item-detail {
        padding: 6px;
        width: 100%;
        border-radius: 4px;
        margin: 12px 0;
        color: black;
        background: #fff !important;
        border-radius: 4px !important;
        box-shadow: 0 1px 1px rgb(0 0 0 / 10%) !important;

        .card-item-dewatering-label {
          margin-left: 24px;
          background-color: #d6c8ee;
          padding: 2px 3px;
          border-radius: 6px;
        }

        .card-item-grinding-label {
          margin-left: 24px;
          background-color: #ebf6ee;
          padding: 2px 6px;
          border-radius: 6px;
        }
      }
    }
  }

  .card-item-actions {
    padding-top: 20px;

    .card-item-action-btn-disable,
    .card-item-action-btn {
      border: 2px solid green;
      background-color: green !important;
      color: #ffffff !important;
      text-transform: uppercase;
      font-weight: bold;
      padding: 0px 10px !important;
      margin-bottom: 20px !important;
    }

    .card-item-action-btn-disable {
      border: 2px solid grey;
      background-color: grey !important;
    }

  }
}

.card-purple {
  .card-item {
    .card-item-header {
      .card-item-title {
        padding: 12px;
        background: #7a5cad;
        color: #ffffff;
      }
    }
  }
}


.ml-20 {
  margin-left: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}
