@import 'antd/dist/antd.less';

// Major vaariables
@primary-color: #46AF5A; // primary color for all components
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@font-size-lg: 18px;
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: #727a72; // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@secondary-color: rgba(0, 0, 0, 0.03);
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #c2c2c2; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

/* ------------------------------ custom colors ----------------------------- */
@blue-color: rgb(71, 196, 219);
@light-green-color: rgb(163, 213, 176);
@green-color: #60b649;
@red-color: rgb(239, 72, 73);
@yellow-color: rgb(239, 212, 88);
@purple-color: rgb(167, 123, 202);
@white-color: #FFFFFF;
@black-color: rgba(0, 0, 0, 0.08);
@light-black-color : rgba(0, 0, 0, 0.85);

// Layout
@layout-body-background: #fff;
@layout-header-background: #60b649;
@layout-header-height: 64px;
@layout-header-padding: 0 50px;
@layout-header-color: @text-color;
@layout-footer-padding: 24px 50px;
@layout-footer-background: @layout-body-background;
@layout-sider-background: #f3f7f4;
@layout-trigger-height: 48px;
@layout-trigger-background: #002140;
@layout-trigger-color: #fff;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;
// Layout light theme
@layout-sider-background-light: #fff;
@layout-trigger-background-light: #fff;
@layout-trigger-color-light: @text-color;

// Buttons
@btn-font-weight: 400;
@btn-border-radius-base: @border-radius-base;
@btn-border-radius-sm: @border-radius-base;
@btn-border-width: @border-width-base;
@btn-border-style: @border-style-base;
@btn-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
@btn-primary-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
@btn-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);

@btn-primary-color: #fff;
@btn-primary-bg: @primary-color;

@btn-default-color: #000;
@btn-default-bg: @component-background;
@btn-default-border: #000;

@btn-danger-color: #fff;
@btn-danger-bg: @error-color;
@btn-danger-border: @error-color;

@btn-disable-color: @disabled-color;
@btn-disable-bg: @disabled-bg;
@btn-disable-border: @border-color-base;

@btn-default-ghost-color: #000;
@btn-default-ghost-bg: transparent;
@btn-default-ghost-border: #000;

@btn-font-size-lg: @font-size-lg;
@btn-font-size-sm: @font-size-base;
@btn-padding-horizontal-base: @padding-md - 1px;
@btn-padding-horizontal-lg: @btn-padding-horizontal-base;
@btn-padding-horizontal-sm: @padding-xs - 1px;

@btn-height-base: @height-base;
@btn-height-lg: @height-lg;
@btn-height-sm: @height-sm;

@btn-line-height: @line-height-base;

@btn-circle-size: @btn-height-base;
@btn-circle-size-lg: @btn-height-lg;
@btn-circle-size-sm: @btn-height-sm;

@btn-square-size: @btn-height-base;
@btn-square-size-lg: @btn-height-lg;
@btn-square-size-sm: @btn-height-sm;
@btn-square-only-icon-size: @font-size-base + 2px;
@btn-square-only-icon-size-sm: @font-size-base;
@btn-square-only-icon-size-lg: @btn-font-size-lg + 2px;

@btn-group-border: @primary-5;

@btn-link-hover-bg: transparent;
@btn-text-hover-bg: rgba(0, 0, 0, 0.018);

// Tabs
// ---
@tabs-card-head-background: @background-color-light;
@tabs-card-height: 40px;
@tabs-card-active-color: @primary-color;
// @tabs-card-horizontal-padding: (@tabs-card-height - floor(@font-size-base * @line-height-base)) / 2 - @border-width-base
//   @padding-md;
@tabs-card-horizontal-padding-sm: 6px @padding-md;
@tabs-card-horizontal-padding-lg: 7px @padding-md 6px;
@tabs-title-font-size: 16px;
@tabs-title-font-size-lg: @font-size-lg;
@tabs-title-font-size-sm: @font-size-base;
@tabs-ink-bar-color: @primary-color;
@tabs-bar-margin: 0 0 @margin-md 0;
@tabs-horizontal-margin: 0 32px 0 32px;
@tabs-horizontal-margin-rtl: 0 0 0 32px;
@tabs-horizontal-padding: @padding-sm 0;
@tabs-horizontal-padding-lg: @padding-md 0;
@tabs-horizontal-padding-sm: @padding-xs 0;
@tabs-vertical-padding: @padding-xs @padding-lg;
@tabs-vertical-margin: 0 0 @margin-md 0;
@tabs-scrolling-size: 32px;
// @tabs-highlight-color: #000;
// @tabs-active-color: @primary-color;
// @tabs-hover-color:@primary-color;
@tabs-card-gutter: 2px;
@tabs-card-tab-active-border-top: 2px solid transparent;

//menuu
@menu-bg: #f3f7f4;
@menu-item-active-color: #fff;
@menu-item-active-bg: @primary-color;
@menu-highlight-color: #000;
@menu-item-color: #000;
@menu-item-boundary-margin: 10px;

//card
@card-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
@card-radius: 8px;
@card-background: #fbfbfb;

//input
@input-height-base: 40px;
@input-border-color: #c2c2c2;
@input-placeholder-color: #c2c2c2;
@input-icon-color: #c2c2c2;
//tag
@tag-font-size: 14px;

.plus-button {
  padding: 0px 10px !important;
  margin: 20px 0px !important;
}

.ml-20 {
  margin-left: 20px;
}

.ml-40 {
  margin-left: 40px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.centred {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}
