.export-page-layout {
    .selector-content {
        margin-top: 2vh;
    }

    .select-input {
        width: 15vw;
        border-color: #ccc !important;
        border-radius: 4px !important;
    }

    h3 {
      margin: 0 !important;
    }

}
  