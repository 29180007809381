@import '../../antd.overrides.less';

.next-breeding {
  .first-title {
    h1 {
      color: @purple-color  !important;
      font-size: 36px;
    }
  }

  .card-item-title {
    font-size: 64px;
    padding: 12px;
    background: @white-color;
    box-shadow: 0px 6px 10px @black-color;
    border-radius: 4px;
    text-align: center;
    color: @purple-color  !important;
    font-weight: bold;
    width: 200px;
  }


  /* ---------------------------------- tabs ---------------------------------- */
  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @purple-color  !important;
  }

  .card-item-header .card-item-title {
    color: @green-color  !important;
  }

  .ant-tabs-ink-bar {
    background: @purple-color  !important;
  }

  /* ---------------------------------- days ---------------------------------- */
  .card-item-container .card-item .card-item-tag .tag-time-off {
    background: @purple-color  !important;
    color: @white-color  !important;
  }
}
