.drying-phase-two-action {

  .action-button-disabled,
  .action-button {
    background-color: #083E50 !important;
    color: white !important;
    padding: 0 10px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
  }

  .action-button-disabled {
    background-color: gray !important;
  }
}
