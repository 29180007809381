@import '../../../antd.overrides.less';

.card-item-container-stock {

  .card-item {
    border: 1px solid #eee;
    border-radius: 6px;

    .ant-card-body {
      padding: 0px 24px !important;
    }

    .card-item-header {
      margin-bottom: 12px;

      .card-item-title {
        font-size: 16px;
        padding: 8px;
        background: rgb(0, 51, 66) !important;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        text-align: center;
        color: white !important;
        font-weight: bold;
      }
    }
  }
}
