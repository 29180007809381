.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100vh;

  .landing-page-spin {
    font-size: 200px
  }
}
