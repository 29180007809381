@import '../../antd.overrides.less';

.first-title {
  h1 {
    color: @primary-color;
    font-size: 36px;
  }
}

.egg-collection-card {

  margin-left: 24px;

  h1,
  h2,
  h3 {
    color: @primary-color;
  }

  .marginZero {
    margin: 0 !important;
  }
  
  .ml-10 {
    margin-left: 10px !important;
  }
}

.next-incubation-fab-button {
  position: fixed;
  bottom: 5%;
  right: 2%;
  width: 60px;
  height: 60px;
  font-size: 32px;
  box-shadow: 0px 14px 22px rgba(0, 0, 0, 0.25);
  z-index: 999999999;
}

.next-incubation-title {
  padding: 24px 0;
}

.batch-list {
  .batch-list-filter {
    padding: 24px 12px;
    background-color: @secondary-color;
    margin-bottom: 12px;

    .next-incubation-search {
      text-align: right;
    }
  }

  .batch-card-item {
    margin-bottom: 12px;
    border-radius: 6px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2) !important;

    .batch-card-btn-more {
      text-align: end;
      color: @primary-color;

      .batch-card-btn-more-content {
        background-color: @secondary-color;
        border: none;
      }
    }

    .batch-card-identifier {
      background: @white-color;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      width: 80px;
      height: 80px;
      text-align: center;

      h3 {
        color: @primary-color;
        font-size: 32px;
      }

      .ant-card-body {
        padding: 10px;
        padding-top: 18px;
      }
    }
  }
}

.ant-btn {
  padding: 0px 4px !important;
}

.content-card-details {
  background: @white-color;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  height: auto;
  padding: 9px;
  margin-bottom: 10px;

  span {
    padding-left: 5px;
  }

  .card-item-footer-btn-more {
    color: #46ae5b;
    margin: auto;
    padding: 0;
  }
}

.ant-tabs {
  color: @black-color;
}


.mt-10 {
  margin-top: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.next-fly {
  .first-title {
    h1 {
      color: @yellow-color  !important;
      font-size: 36px;
    }
  }

  .card-item-title {
    font-size: 64px;
    padding: 12px;
    background: @white-color;
    box-shadow: 0px 6px 10px @black-color;
    border-radius: 4px;
    text-align: center;
    color: @yellow-color  !important;
    font-weight: bold;
    width: 200px;
  }
  
  .header-container{
    .bulk-actions-btn {
      width: 12rem;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
    }

    .ant-select:not(.ant-select-customize-input) {
      width: 12rem;
    }
    
  }

  /* ---------------------------------- tabs ---------------------------------- */
  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @yellow-color  !important;
  }

  .card-item-header .card-item-title {
    color: @green-color  !important;
    font-size: 64px;
    padding: 12px;
    background: @white-color !important;
    box-shadow: 0px 6px 10px @black-color;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    width: 200px;
  }

  .ant-tabs-ink-bar {
    background: @yellow-color  !important;
  }

  /* ---------------------------------- days ---------------------------------- */
  .card-item-container .card-item .card-item-tag .tag-time-off {
    background: @yellow-color  !important;
    color: @white-color  !important;
  }
}
