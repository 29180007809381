@import url('../../antd.overrides.less');

.next-larvae-page {
  .dashbaord-filters{
    display: flex;
    align-items: center;
    .dashbaord-filter{
      margin-right: 2rem;
    }
    .single-rate{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #e9e9e9;
      color: @btn-default-color;
      margin-right: 2rem;
      margin-bottom: 2rem;
      width: 200px;
      padding: 1rem;
      border-top-right-radius: 25px;
      span,p {
        margin: 0;
        font-size: 16px;
      }
    }
  }
  .first-title {
    h1 {
      color: @light-green-color  !important;
      font-size: 36px;
    }
  }

  .card-item-title {
    font-size: 64px;
    padding: 12px;
    background: @white-color;
    box-shadow: 0px 6px 10px @black-color;
    border-radius: 4px;
    text-align: center;
    color: @light-green-color  !important;
    font-weight: bold;
    width: 200px;
  }

  /* ---------------------------------- tabs ---------------------------------- */
  .ant-tabs-tab {
    text-transform: uppercase !important;
    padding-bottom: 0 !important;
  }

  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @light-green-color  !important;
    text-transform: uppercase !important;
    font-weight: bold;
  }

  .card-item-header .card-item-title {
    color: @green-color  !important;
  }

  .ant-tabs-ink-bar {
    background: @light-green-color  !important;
  }

  /* ---------------------------------- days ---------------------------------- */
  .card-item-container .card-item .card-item-tag .tag-time-off {
    background: @light-green-color  !important;
    color: @white-color  !important;
    font-weight: bold;
  }
}
