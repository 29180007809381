@import url('../../antd.overrides.less');

.dashbaord-chart{
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 2rem;
  }
  
  td, th {
    border: 1px solid #e9e9e9;
    text-align: left;
    padding: 8px;
    .ant-picker {
      width: 100%;
    }
  }
  
  tr:nth-child(even) {
    background-color: #e9e9e9;
  }
  
  .dashbaord-filter{
    margin-bottom: 1rem;
  }

  .rates-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 2rem 0;
    .single-row {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      .single-rate{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #e9e9e9;
        color: @btn-default-color;
        margin-right: 2rem;
        margin-bottom: 2rem;
        width: 200px;
        padding: 1rem;
        border-top-right-radius: 25px;
        span,p {
          margin: 0;
          font-size: 16px;
        }
      }
    }
  }
  .all-charts{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .single-chart{
      margin-bottom: 2rem;
      margin-right: 2rem;
      width: 100%;
      .chart-title{
        margin-left: 2rem;
        font-size: 18px;
      }
    }
  }
}

.next-feed {
  .container {
     margin: 40px 0 15px 0;
  }
  .marginZero {
    margin: 0;
  }
  .next-feed-title {
    color: @green-color  !important;
    font-size: 25px;
    margin: 0 20px 0 0;
  }
  .next-feed-button {
      margin-left: 30px;
  }
}


.next-incubator {
  .first-title {
    h1 {
      color: @blue-color  !important;
      font-size: 36px;
    }
  }

  .card-item-title {
    font-size: 64px;
    padding: 12px;
    background: @white-color;
    box-shadow: 0px 6px 10px @black-color;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    width: 200px;
  }

  .next-incubator-comment {
    color: @blue-color  !important;
    font-size: 24px;
  }

  .default-color {
    color: @blue-color  !important;
  }

  .nextLarvae-color {
    color :  @light-green-color !important;
  }

  .nextFeed-color {
    color : @green-color !important;
  }

  .nextBreeding-color {
    color: @purple-color !important;
  }

  .batch-details-comments {
    margin: 0 1rem 0 0;
    font-size: 15px;
    color: @light-black-color !important;
    font-weight: 500 !important;
  }
 
  /* ---------------------------------- tabs ---------------------------------- */
  .ant-tabs-tab {
    text-transform: uppercase !important;
    padding-bottom: 0 !important;
  }

  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @blue-color  !important;
    text-transform: uppercase !important;
    font-weight: bold;
  }

  .card-item-header .card-item-title {
    color: @green-color  !important;
  }

  .ant-tabs-ink-bar {
    background: @blue-color  !important;
  }

  /* ---------------------------------- days ---------------------------------- */
  .card-item-container .card-item .card-item-tag .tag-time-off {
    background: @white-color  !important;
    color: @blue-color  !important;
    font-weight: bold;
  }

}
